import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Pagination} from "../../../core/shared/model/pagination";
import {User} from "../../../core/shared/model/user";
import {UserService} from "../../../core/service/user.service";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {

  page = 0;
  usersPage: Pagination<User> = new Pagination<User>();
  showUserDeletePopup = false;
  deleteAdminId = 0;

  @Output() editUser: EventEmitter<User> = new EventEmitter<User>();
  @ViewChild('nameInput', {static: false}) name: ElementRef | undefined;
  constructor(private userService: UserService) {
    this.findByName();
  }

  prevPage(): void{
    if (this.page!==0){
      this.page--;
      this.findByName()
    }
  }

  nextPage(): void{
    if (this.usersPage.totalPages != this.page+1){
      this.page++;
      this.findByName()
    }
  }

  findByName(): void{
    this.userService.getPaginationByName(this.page, this.name ? this.name.nativeElement.value : '').subscribe((data) => this.usersPage = data);
  }

  deleteAdmin(): void{
    this.userService.deleteAdmin(this.deleteAdminId).subscribe((response) => {
      this.showUserDeletePopup = false;
      this.usersPage.content = this.usersPage.content.filter(e => e.id !== this.deleteAdminId);
    })
  }

}
