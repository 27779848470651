import {Injectable} from "@angular/core";
import {HttpBackend, HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {User} from "../shared/model/user";

@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient, handler: HttpBackend ) {
    this.http = new HttpClient(handler);
  }

  public login(username:string, password: string): Observable<User>{
    const headers = new HttpHeaders({Authorization: 'Basic '+btoa(unescape(encodeURIComponent(username+":"+password))),
      'X-Requested-With': 'XMLHttpRequest'})
    return this.http.post<User>(environment.apiUrl + "/admin/login",null,{headers, withCredentials: true});
  }

  public logout() : Observable<any>{
    return this.http.get(environment.serverUrl + "logout",{withCredentials: true, observe: 'response'});
  }
}
