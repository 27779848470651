<div *ngIf="showInputError" class="row">
  <div style="background-color: red; width: 400px" class="message row margin-center-hoz">
    <p>This page has empty fields. Please re-check</p>
  </div>
</div>
<h4>{{event.id === null ? 'New Event' : 'Event Editing'}}</h4>
<form id="newEvent" (ngSubmit)="submit()" [formGroup]="createEventGroup">
  <label for="weekday">Weekday</label>
  <ng-select style="margin-bottom: 30px" id="weekday" formControlName="weekday" [items]="weekdays"  (change)="weekdaySelectorChanged($event, false)"></ng-select>

  <label for="agendaElement">Agenda Element</label>
  <ng-select style="margin-bottom: 30px" id="agendaElement" formControlName="agendaElement" [items]="agendaElements" bindLabel="title" bindValue="agendaIndex" (change)="agendaElementSelectorChanged($event)"></ng-select>

  <label for="title">Title*</label>
  <input [class.input-error]="submitted && createEventGroup.controls['title'].invalid" formControlName="title"
         id="title" placeholder="Title*" type="text">

  <label for="description">Description*</label>
  <textarea style="width: 100%; height: 200px; resize: vertical" [class.input-error]="submitted && createEventGroup.controls['description'].invalid" formControlName="description"
            id="description" placeholder="Description*" ></textarea>

  <label for="startDate">Start Date*</label>
  <input [class.input-error]="submitted && createEventGroup.controls['startDate'].invalid" formControlName="startDate"
         id="startDate" type="date">

  <label for="startTime">Start Time*</label>
  <input [class.input-error]="submitted && createEventGroup.controls['startTime'].invalid" formControlName="startTime"
         id="startTime" type="time">

  <label for="endTime">End Time*</label>
  <input [class.input-error]="submitted && createEventGroup.controls['endTime'].invalid" formControlName="endTime"
         id="endTime" type="time">

  <label for="zoomLink">Zoom CTA Link*</label>
  <input [class.input-error]="submitted && createEventGroup.controls['zoomLink'].invalid" formControlName="zoomLink"
         id="zoomLink" placeholder="http://example.com" type="text">

  <div (click)="submit()" class="submit-button button"><p
    style="color: white; margin: auto">Save</p></div>
</form>
