<div class="global flex-column">
  <div class="tab-selector row">
    <div [class.active]="tabActive === 'changeUsername'" (click)="tabActive = 'changeUsername'"><p>Username</p></div>
    <div [class.active]="tabActive === 'changePassword'" (click)="tabActive = 'changePassword'"><p>Password</p></div>
  </div>
  <div *ngIf="successful" style="background-color: green;" class="message row margin-center-hoz">
    <p>Successful!</p>
  </div>
  <div *ngIf="error.length !== 0" style="background-color: red;" class="message row margin-center-hoz">
    <p>{{error}}</p>
  </div>
  <form [formGroup]="editUser" class="outer">
    <div [hidden]="tabActive !== 'changeUsername'">
      <label for="username">New username</label>
      <input [class.input-error]="editUser.controls['username'].invalid" #username type="text" id="username" (change)="user.email = username.value" formControlName="username">
      <p *ngIf="editUser.controls['username'].invalid" style="color: red">Username required</p>

    </div>
    <div [hidden]="tabActive !== 'changePassword'">
      <label for="password">New password*</label>
      <input [class.input-error]="editUser.controls['password'].invalid" (input)="addValidators()" id="password" type="password" formControlName="password">
      <p *ngIf="editUser.controls['password'].invalid" style="color: red">Password required</p>


      <label for="repeatPassword">Repeat password*</label>
      <input [class.input-error]="editUser.hasError('notSame')" id="repeatPassword" type="password" formControlName="repeatPassword">
      <p *ngIf="editUser.hasError('notSame')" style="color: red">Passwords not equal</p>

    </div>
    <div (click)="submit()" class="submit-button button"><p style="color: white; margin: auto">Submit</p></div>
  </form>
</div>
