import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Pagination} from "../shared/model/pagination";
import {User} from "../shared/model/user";
import {environment} from "../../../environments/environment";
import {UserSurvey} from "../shared/model/userSurvey";

@Injectable()
export class SurveyService {
  constructor(private http: HttpClient,
              @Inject('API_REST_URL_ADMIN_SURVEY') private surveyUrl: string) {
  }

  public getSurveysByDay(day: string){
    return this.http.get<UserSurvey[]>(environment.apiUrl + this.surveyUrl + "/" + day, {withCredentials: true});
  }
}
