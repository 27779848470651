<div style="display: flex" class="container">
  <div [style.visibility]="showError ? 'visible' : 'hidden'" [style.opacity]="showError ? '1' : '0'" style="background-color: red; width: 300px; top: 50px; position: absolute" class="message row">
    <p>Incorrect username or password</p>
  </div>
  <div class="outer">
    <div class="inner flex-column">
      <h2 class="title margin-center-hoz">Welcome back!</h2>
      <form (keydown.enter)="login()" [formGroup]="loginForm">
        <div>
          <label for="login">Username</label>
          <input [style.border]="loginSubmitted && loginForm.controls['login'].invalid ? '1px solid red' : '1px solid #CED4DA'" formControlName="login"
                 id="login" type="text"/>
          <p style="color: red" *ngIf="loginSubmitted && loginForm.controls['login'].invalid">Login required</p>
        </div>
        <div>
          <label for="password">Password</label>
          <input [style.border]="loginSubmitted && loginForm.controls['password'].invalid ? '1px solid red' : '1px solid #CED4DA'" formControlName="password"
                 id="password" type="password"/>
          <p style="color: red" *ngIf="loginSubmitted && loginForm.controls['password'].invalid">Password required</p>
        </div>
      </form>
      <div (click)="login()" class="button">
        <p>Log in</p>
      </div>
    </div>
  </div>
</div>
