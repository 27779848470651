<div class="global">
  <div style="align-items: center; margin-bottom: 30px" class="row">
    <h4>Events</h4>
    <p class="breadscrumbs">Admin/Cms/Events</p>
  </div>
  <div class="tab-selector">
    <div [class.active]="tabActive === 'newEvent'" style="background-color: brown"
         (click)="tabActive = 'newEvent'; scrollToNewEventForm()">
      <p>New Event</p>
    </div>
  </div>
  <div *ngIf="successful" class="row">
    <div style="background-color: green;" class="message row margin-center-hoz">
      <p>Successful!</p>
    </div>
  </div>
  <div *ngIf="error" class="row">
    <div style="background-color: red;" class="message row margin-center-hoz">
      <p>Something goes wrong!</p>
    </div>
  </div>
  <div class="main-block-outer">
    <div class="main-block-inner">
      <div class="row">
        <input [value]="" #nameInput (keydown.enter)="findByTitle()" type="text" placeholder="Search">
      </div>
      <table>
        <tr>
          <td>№</td>
          <td>Title</td>
          <td>Start Date</td>
          <td>Start Time</td>
          <td>End Time</td>
          <td>Actions</td>
        </tr>
        <tr *ngFor="let event of eventsPage.content; index as i">
          <td>{{(eventsPage.size * page + 1) + i}}</td>
          <td>{{event.title.length > 20 ? event.title.slice(0, 17) + '...' : event.title}}</td>
          <td>{{convertDateDdMmYyyy(event.startDate)}}</td>
          <td>{{event.startTime}}</td>
          <td>{{event.endTime}}</td>
          <td class="row table-actions">
            <a style="margin-right: 12px;" [href]="environment.serverUrl + 'events'" target="_blank"><div class="row"><img style="width: 18px" src="/assets/ico/open.png"><p style="color: black">Open</p></div></a>
            <div (click)="openEventEditing(event)" class="row"><img src="/assets/ico/ic_edit.png">
              <p>Edit</p></div>
            <div (click)="eventForDelete = event.id; showDeletePopup = true" class="row"><img
              src="/assets/ico/ic_cancel.png">
              <p>Delete</p></div>
          </td>
        </tr>
      </table>
      <div class="table-info row">
        <p class="recs-per-page">{{eventsPage.size}} records per page</p>
        <div class="row" style="margin-left: auto; align-items: center">
          <div style="width: 40px" class="rectangle"><p>{{page + 1}}</p></div>
          <p style="margin-left: 16px; margin-right: 16px">of {{eventsPage.totalPages}}</p>
          <div (click)="prevPage()" class="rectangle button"><img
            [style.filter]="eventsPage.first ? '' : 'brightness(0.1)'" style="transform: rotate(180deg)"
            src="/assets/ico/arrow_right.png"></div>
          <div (click)="nextPage()" class="rectangle button"><img
            [style.filter]="eventsPage.last ? '' : 'brightness(0.1)'" src="/assets/ico/arrow_right.png"></div>
        </div>
      </div>
    </div>
  </div>
  <app-new-event (showLoading)="onShowLoading()" (successful)="onShowSuccessful()" (error)="onShowError()"
               *ngIf="tabActive==='newEvent'"></app-new-event>
  <app-new-event [event]="eventForEdit" (showLoading)="onShowLoading()" (successful)="onShowSuccessful()"
               (error)="onShowError()" *ngIf="tabActive==='editEvent'"></app-new-event>
</div>
<div *ngIf="showLoading" class="shadow">
  <img src="/assets/gif/loading.gif">
</div>
<div class="shadow" *ngIf="showDeletePopup">
  <div class="delete-popup">
    <div class="header row">
      <h6>Delete Event</h6>
      <img (click)="showDeletePopup = false" class="button" src="/assets/ico/close_grey.png">
    </div>
    <hr>
    <div class="delete-main-block">
      <p>Are you sure you want to delete this Event?</p>
      <div class="row">
        <div (click)="delete()" style="margin-left: auto; margin-right: 16px; background: #64DE9D;" class="button">
          <p>Delete</p>
        </div>
        <div (click)="showDeletePopup = false" style="background: #CED4DA;" class="button">
          <p>Cancel</p>
        </div>
      </div>
    </div>
  </div>
</div>
