export class Event {
  id: number;
  startDate: string;
  startTime: string;
  endTime: string;
  title: string;
  description: string;
  zoomLink: string;
  day: string;
  agendaIndex: number;

  constructor(obj?: any) {
    this.id = obj && obj.id || null;
    this.startDate = obj && obj.startDate || null;
    this.startTime = obj && obj.startTime || null;
    this.endTime = obj && obj.endTime || null;
    this.title = obj && obj.title || null;
    this.description = obj && obj.description || null;
    this.zoomLink = obj && obj.zoomLink || null;
    this.day = obj && obj.day || null;
    this.agendaIndex = obj && obj.agendaIndex || null;
  }
}
