<div style="height: 100%" *ngIf=!showLogin>
  <div style="height: 100%" class="row">
    <div class="panel">
      <div class="panel-inner">
        <div (click)="activeBlock='events';resetTitle()" [class.active]="activeBlock==='events'">
          <img src="/assets/ico/file.png">
          <p>Events</p>
        </div>
        <div (click)="activeBlock='surveys';resetTitle()" [class.active]="activeBlock==='surveys'">
          <img src="/assets/ico/file.png">
          <p>Surveys</p>
        </div>
        <div (click)="activeBlock='users';resetTitle()" [class.active]="activeBlock==='users'">
          <img src="/assets/ico/ic_user.png">
          <p>Users</p>
        </div>
      </div>
    </div>
    <div style="width: calc(100% - 320px)" class="flex-column">
      <app-header (newUserClicked)="activeBlock = 'newUser'" [user]="user"></app-header>
      <app-events *ngIf="activeBlock==='events'"></app-events>
      <app-surveys *ngIf="activeBlock==='surveys'"></app-surveys>
      <app-users *ngIf="activeBlock === 'users'" (editUser)="userForEdit = $event; activeBlock = 'editUser'"></app-users>
      <app-new-user *ngIf="activeBlock === 'newUser'"></app-new-user>
      <app-edit-user *ngIf="activeBlock === 'editUser'" [user]="userForEdit"></app-edit-user>
      <app-footer style="margin-top: auto"></app-footer>
    </div>
  </div>
</div>
