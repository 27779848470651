import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Event} from "../../../../core/shared/model/event";
import {DatePipe} from "@angular/common";
import {EventService} from "../../../../core/service/event.service";
import {ChallengeService} from "../../../../core/service/challenge.service";
import {ChallengeAgendaElement} from "../../../../core/shared/model/challengeAgendaElement";
import {NgSelectComponent} from "@ng-select/ng-select";

@Component({
  selector: 'app-new-event',
  templateUrl: './new-event.component.html',
  styleUrls: ['./new-event.component.scss']
})
export class NewEventComponent implements OnInit, OnChanges {
  createEventGroup: FormGroup;
  submitted = false;
  weekdays = ['monday','tuesday', 'wednesday', 'thursday', 'friday']
  agendaElements: ChallengeAgendaElement[] = [];
  @Input() event: Event = new Event();
  @Output() showLoading: EventEmitter<any> = new EventEmitter<any>();
  @Output() successful: EventEmitter<any> = new EventEmitter<any>();
  @Output() error: EventEmitter<any> = new EventEmitter<any>();
  showInputError = false;

  constructor(public fb: FormBuilder, private datePipe: DatePipe, private eventService: EventService, private challengeService: ChallengeService) {
    this.createEventGroup = this.fb.group({});
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fillGroup();
  }

  ngOnInit(): void {
    this.fillGroup();
    if (this.event.id && this.event.id !== 0){
      this.weekdaySelectorChanged(this.event.day, true);
    }
  }

  fillGroup() {
    this.createEventGroup = this.fb.group({
      title: [this.event.title, Validators.required],
      startDate: [this.convertDateYyyyMmDd(this.event.startDate), Validators.required],
      startTime: [this.event.startTime, Validators.required],
      endTime: [this.event.endTime, Validators.required],
      description: [this.event.description, Validators.required],
      weekday: [this.event.day],
      agendaElement: [this.event.agendaIndex],
      zoomLink: [this.event.zoomLink, Validators.required],
    });
    this.onChanges();
  }

  convertDateToNumber(date: string): string{
    if (date === null || date === '') return '';
    return new Date(date).valueOf().toString();
  }

  convertDateYyyyMmDd(date: string): string{
    if (date === null || date === '') return '';
    return <string>this.datePipe.transform(new Date(Number(date)), "yyyy-MM-dd");
  }

  onChanges(): void {
    this.createEventGroup.valueChanges.subscribe(val => {
      this.event.title = this.createEventGroup.controls["title"].value;
      this.event.startDate = this.convertDateToNumber(this.createEventGroup.controls["startDate"].value);
      this.event.startTime = this.createEventGroup.controls["startTime"].value;
      this.event.endTime = this.createEventGroup.controls["endTime"].value;
      this.event.description = this.createEventGroup.controls["description"].value;
      this.event.zoomLink = this.createEventGroup.controls["zoomLink"].value;
    });
  }

  submit() {
    this.submitted = true;
    if (!this.checkFormValid()) {
      setTimeout(() => {
        this.showInputError = false;
      }, 5000);
      return;
    }
    this.showLoading.emit();
    this.eventService.create(this.event).subscribe((response) => {
        this.showLoading.emit();
        this.successful.emit();
      },
      (error) => {
        this.showLoading.emit();
        this.error.emit();
      })
  }

  checkFormValid(): boolean {
    if (this.createEventGroup.invalid) {
      this.showInputError = true;
      this.scrollToTop();
    }
    return !this.showInputError;
  }

  scrollToTop(): void {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  weekdaySelectorChanged(event: any, initial: boolean): void{
    if (!event) this.agendaElements = [];
    if (!initial) this.createEventGroup.controls['agendaElement'].setValue('');
    else this.challengeService.getAgendaVideoElementsByDay(event).subscribe((data) => this.agendaElements = data);

    this.event.agendaIndex = event;
    this.event.day = event;
  }

  agendaElementSelectorChanged(event: ChallengeAgendaElement): void{
    if (!event) this.event.agendaIndex = event;
    else this.event.agendaIndex = event.agendaIndex;
  }
}
