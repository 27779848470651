import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { Papa } from 'ngx-papaparse';
import {SurveyService} from "../../../core/service/survey.service";

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss']
})
export class SurveysComponent implements OnInit {
  selectedWeekday = '';
  weekdays: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
  csvHeader = ['Name', 'Email', '1. Was the day interesting?', '1. Why', '2. Was it engaging?', '2. Why', '3. Did the content make sense to you?', "3. Why", '4. Did it give you information to help inform your career choices?', '4. Why', '5. Was it enjoyable?', '5. Why', '6. Was there anything missing from the presentations you would have liked to have seen?', '6. Why', '7. Do you think the session can be improved?', '7. Why', '8. Did you find the timings too long/short/just right?', '8. Why', '9. Did you like the slides/content?', '9. Why', '10. How easy/difficult did you find the project?', '10. Why', '11. Any other feedback/thoughts you have?'];
  csvFridayAdditionalHeaders = ['12. Thinking about the week as a whole, how much did you enjoy the work experience?', '12. Why', '13. Do you feel like your knowledge of AstraZeneca and career opportunities increased during the week?', '13. Why', '14. Do you feel like you have been inspired by our real life superheroes throughout the week?', '14. Why', '15. Would you recommend this virtual work experience opportunity to your school and/or your friends?', '15. Why']
  constructor(private surveyService: SurveyService, private papa: Papa) { }

  ngOnInit(): void {
  }

  download(){
    const data: any[] = [this.csvHeader];
    if (this.selectedWeekday === 'friday') data[0] = data[0].concat(this.csvFridayAdditionalHeaders);
    data[0] = data[0].concat('timestamp')
    this.surveyService.getSurveysByDay(this.selectedWeekday).subscribe((surveys) => {
      surveys.forEach(survey => {
        let row: any[] = [];
        row.push(survey.firstName, survey.email);
        survey.surveyResponse.answers.forEach(answer => {
          row.push(answer.answer);
          if (answer.number !== 11) row.push(answer.why);
        })
        if (survey.surveyResponse.timestamp) row.push(new Date(survey.surveyResponse.timestamp))
        data.push(row);
      })
      const csv = this.papa.unparse(data);

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, this.selectedWeekday + '.csv');
    });
  }
}
