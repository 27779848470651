import {Injectable} from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";

@Injectable()
export class HeadersInterceptor implements HttpInterceptor{

  constructor(private route: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('login')){
      request = request.clone({
        setHeaders: {
          Authorization: '',
        }
      });
    }
    request = request.clone({
      setHeaders: {
        'X-Requested-With': 'XMLHttpRequest',
      }
    });
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
      if (error.status === 401) this.route.navigateByUrl('/login');
      return throwError(error);
    }));
  }

}
