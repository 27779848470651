import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {ChallengeAgendaElement} from "../shared/model/challengeAgendaElement";

@Injectable()
export class ChallengeService {
  constructor(private http: HttpClient,
              @Inject('API_REST_URL_ADMIN_CHALLENGES') private challengesUrl: string) {
  }

  public getAgendaVideoElementsByDay(weekday: string): Observable<ChallengeAgendaElement[]>{
    return this.http.get<ChallengeAgendaElement[]>(environment.apiUrl + this.challengesUrl + '/' + weekday + '/agenda/video', {withCredentials: true});
  }
}
