import {environment} from "../../environments/environment";

export const APP_ENDPOINTS = [
  {provide: 'API_REST_URL_ADMIN', useValue: environment.adminPrefix + environment.usersPrefix},

  {provide: 'API_REST_URL_ADMIN_EVENT_PAGES', useValue: environment.adminPrefix + environment.liveNowEventsPrefix},

  {provide: 'API_REST_URL_ADMIN_CHALLENGES', useValue: environment.adminPrefix + environment.challengesPrefix},
  {provide: 'API_REST_URL_ADMIN_SURVEY', useValue: environment.adminPrefix + environment.surveyPrefix},
];
