import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {UserService} from "../../../core/service/user.service";
import {User} from "../../../core/shared/model/user";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  createUserGroup: FormGroup;
  formSubmitted = false;
  showLoading = false;
  error = false;
  succesfull = false;

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    if (group === null) return { notSame: true };
    // @ts-ignore
    let pass = group.get('password').value;
    // @ts-ignore
    let confirmPass = group.get('repeatPassword').value;
    return pass === confirmPass ? null : { notSame: true }
  }

  constructor(public fb: FormBuilder, private userService: UserService) {
    this.createUserGroup = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(4)]],
      password: ['', [Validators.required, Validators.minLength(4)]],
      repeatPassword: ['', Validators.required]
    }, {validators: this.checkPasswords});
  }

  submit(): void{
    this.formSubmitted = true;
    if (this.createUserGroup.invalid) return;
    this.showLoading = true;
    let user = new User();
    user.email = this.createUserGroup.controls['username'].value;
    user.password = this.createUserGroup.controls['password'].value;
    this.userService.createAdmin(user).subscribe((response) => {
      this.showLoading = false;
      this.succesfull = true;
        setTimeout(() => {
          this.succesfull = false;
        }, 5000);
    },
      (error: HttpErrorResponse) => {
        this.showLoading = false;
        if (error.status === 409) {
          this.error = true;
          setTimeout(() => {
            this.error = false;
          }, 5000);
        }
      })
  }

  ngOnInit(): void {
  }

}
