<div class="global flex-column">
  <div *ngIf="succesfull" style="background-color: green;" class="message row margin-center-hoz">
    <p>Successful!</p>
  </div>
  <div *ngIf="error" style="background-color: red;" class="message row margin-center-hoz">
    <p>This user already exists!</p>
  </div>
  <div class="outer">
    <form (ngSubmit)="submit()" [formGroup]="createUserGroup">
      <label for="username">Username*</label>
      <input [class.input-error]="formSubmitted && createUserGroup.controls['username'].invalid"
             formControlName="username" id="username" type="text">
      <p *ngIf="formSubmitted && createUserGroup.controls['username'].invalid" style="color: red">Username required</p>

      <label for="password">Password*</label>
      <input [class.input-error]="formSubmitted && createUserGroup.controls['password'].invalid"
             formControlName="password" id="password" type="password">
      <p *ngIf="formSubmitted && createUserGroup.controls['password'].invalid" style="color: red">Password required</p>

      <label for="repeatPassword">Repeat password*</label>
      <input [class.input-error]="formSubmitted && createUserGroup.errors" formControlName="repeatPassword"
             id="repeatPassword" type="password">
      <p *ngIf="formSubmitted && createUserGroup.errors" style="color: red">Passwords not equal</p>

      <div (click)="submit()" class="submit-button button margin-center-hoz"><p style="color: white; margin: auto">
        Submit</p></div>
    </form>
  </div>
</div>
<div *ngIf="showLoading" class="shadow">
  <img src="/assets/gif/loading.gif">
</div>
