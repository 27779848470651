import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {User} from "../../../core/shared/model/user";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {UserService} from "../../../core/service/user.service";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  editUser: FormGroup;
  successful = false;
  error = '';

  tabActive = 'changeUsername';
  @Input() user: User = new User();

  constructor(public fb: FormBuilder, private userService: UserService) {
    this.editUser = this.fb.group({
     });
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    if (group === null) return { notSame: true };
    // @ts-ignore
    let pass = group.get('password').value;
    // @ts-ignore
    let confirmPass = group.get('repeatPassword').value;
    return pass === confirmPass ? null : { notSame: true }
  }

  ngOnInit(): void {
    this.editUser = this.fb.group({
      username: [this.user.email, [Validators.required, Validators.minLength(4)]],
      password: [null],
      repeatPassword: [null]
    });
  }

  addValidators(): void{
    if (this.editUser.controls['password'].value.length === 0){
      this.editUser.controls['password'].setValidators(null);
      this.editUser.setValidators(null);

      this.editUser.controls['password'].updateValueAndValidity();
      this.editUser.updateValueAndValidity();
      return;
    }
    this.editUser.controls['password'].setValidators([Validators.required, Validators.minLength(4)]);
    this.editUser.controls['password'].updateValueAndValidity();

    this.editUser.setValidators(this.checkPasswords);
    this.editUser.updateValueAndValidity();
  }

  submit(): void{
    if (this.editUser.invalid){
      if (!this.editUser.controls['username'].invalid) {
        this.tabActive = 'changePassword';
        return;
      }
    }
    this.user.password = 'awdawd';
    this.user.newPassword = this.editUser.controls['password'].value;
    this.userService.updateAdmin(this.user).subscribe((response) => {
      this.successful = true;
        setTimeout(() => {
          this.successful = false;
        }, 5000);
    },
      (error: HttpErrorResponse) => {
        if (error.status === 403) {
          if (error.error === 'Forbidden username'){
            this.error = 'Username already used';
          }
        }
        setTimeout(() => {
          this.error = '';
        }, 5000);
      })
  }
}
