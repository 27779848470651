export class Page<T> {
  content: T[];
  last:boolean;
  totalPages : number;
  totalElements: number;
  size: number;
  number: number;
  first: boolean;
  numberOfElements: number;
  empty: boolean;

  constructor(obj?: any) {
    this.content = obj && obj.content || [];
    this.last = obj && obj.last || null;
    this.totalPages = obj && obj.totalPages || 0;
    this.totalElements = obj && obj.totalElements || null;
    this.size = obj && obj.size || null;
    this.number = obj && obj.number || null;
    this.numberOfElements = obj && obj.numberOfElements || null;
    this.first = obj && obj.first || null;
    this.empty = obj && obj.empty || null;
  }
}
