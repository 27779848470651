<div class="global">
  <div style="align-items: center; margin-bottom: 30px" class="row">
    <h4>Surveys</h4>
    <p class="breadscrumbs">Admin/Cms/Surveys</p>
  </div>
  <div class="main-block-outer">
    <div class="main-block-inner">
      <label for="weekday">Select weekday</label>
      <ng-select style="margin-bottom: 30px;margin-top: 10px" id="weekday" [(ngModel)]="selectedWeekday" [items]="weekdays"></ng-select>
      <button (click)="download()" [disabled]="!selectedWeekday" class="submit-button button">Download</button>
    </div>
  </div>
</div>
