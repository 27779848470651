import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Page} from "../../../core/shared/model/page";
import {Event} from "../../../core/shared/model/event";
import { environment } from 'src/environments/environment';
import {EventService} from "../../../core/service/event.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent {

  tabActive = '';
  eventsPage: Page<Event> = new Page<Event>();
  page = 0;
  @ViewChild('nameInput', {static: false}) name: ElementRef | undefined;
  environment = environment;
  eventForEdit: Event = new Event();
  eventForDelete: number = 0;
  showDeletePopup = false;
  showLoading = false;
  successful = false;
  error = false;

  constructor(private eventService: EventService, private datePipe: DatePipe) {
    this.findByTitle();
  }

  openEventEditing(event: Event) {
    this.eventForEdit = event;
    this.tabActive = 'editEvent';
    this.scrollToNewEventForm();
  }

  scrollToNewEventForm() {
    // @ts-ignore
    setTimeout(() => {document.getElementById('newEvent').scrollIntoView({behavior: "smooth", block: "start"});}, 100);
  }

  onShowLoading() {
    this.showLoading = !this.showLoading;
  }

  onShowSuccessful() {
    this.successful = true;
    this.scrollToTop();
    this.tabActive = '';
    this.findByTitle();
    setTimeout(() => {
      this.successful = false;
    }, 5000);
  }

  onShowError() {
    this.error = true;
    this.scrollToTop();
    setTimeout(() => {
      this.error = false;
    }, 5000);
  }

  scrollToTop(): void {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  prevPage(): void {
    if (this.page !== 0) {
      this.page--;
      this.findByTitle()
    }
  }

  replaceAll(text: string) {
    return text.replace(new RegExp(' ', 'g'), '-');
  }

  nextPage(): void {
    if (this.eventsPage.totalPages != this.page + 1) {
      this.page++;
      this.findByTitle()
    }
  }

  findByTitle(): void {
    this.eventService.getPaginationByTitle(this.page, this.name ? this.name.nativeElement.value : '').subscribe((data) => this.eventsPage = data);
  }

  delete(): void {
    this.eventService.delete(this.eventForDelete).subscribe((response) => {
      this.showDeletePopup = false
      this.eventsPage.content = this.eventsPage.content.filter(e => e.id !== this.eventForDelete);
    });
  }

  convertDateDdMmYyyy(date: string): string {
    if (date === null || date === '') return '';
    return <string>this.datePipe.transform(new Date(Number(date)), "dd.MM.yyyy");
  }
}
