import {NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { CmsComponent } from './components/cms/cms.component';
import { UsersComponent } from './components/cms/users/users.component';
import { NewUserComponent } from './components/cms/new-user/new-user.component';
import { LoginComponent } from './components/cms/login/login.component';
import {APP_ENDPOINTS} from "./core/app.endpoint";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {AuthenticationService} from "./core/service/authentication.service";
import {UserService} from "./core/service/user.service";
import {HeadersInterceptor} from "./core/interceptor/headersInterceptor";
import { EventsComponent } from './components/cms/events/events.component';
import { NewEventComponent } from './components/cms/events/new-event/new-event.component';
import { EditUserComponent } from './components/cms/edit-user/edit-user.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule, DatePipe} from "@angular/common";
import {EventService} from "./core/service/event.service";
import {NgSelectModule} from "@ng-select/ng-select";
import {ChallengeService} from "./core/service/challenge.service";
import { SurveysComponent } from './components/cms/surveys/surveys.component';
import {SurveyService} from "./core/service/survey.service";

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    CmsComponent,
    UsersComponent,
    NewUserComponent,
    LoginComponent,
    EventsComponent,
    NewEventComponent,
    EditUserComponent,
    SurveysComponent
  ],
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
    ],
  providers: [APP_ENDPOINTS,
    HttpClient,
    AuthenticationService,
    UserService,
    DatePipe,
    EventService,
    ChallengeService,
    SurveyService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true
    }
    ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
