<div class="global">
  <div style="align-items: center" class="row">
    <h4>Users</h4>
    <p class="breadscrumbs">Admin/Cms/Users</p>
  </div>
  <div class="main-block-outer">
    <div class="main-block-inner">
      <div class="row">
        <input [value]="" #nameInput (keydown.enter)="findByName()" type="text" placeholder="Search">
      </div>
      <table>
        <tr>
          <td>№</td>
          <td>Username</td>
          <td>Actions</td>
        </tr>
        <tr *ngFor="let user of usersPage.content; index as i">
          <td>{{(usersPage.size * page + 1) + i}}</td>
          <td>{{user.email}}</td>
          <td class="row table-actions">
            <div (click)="editUser.emit(user)" class="row"><img src="/assets/ico/ic_edit.png"><p>Edit</p></div>
            <div (click)="showUserDeletePopup = true; deleteAdminId = user.id" class="row"><img src="/assets/ico/ic_cancel.png"><p>Delete</p></div>
          </td>
        </tr>
      </table>
      <div class="table-info row">
        <p class="recs-per-page">{{usersPage.size}} records per page</p>
        <div class="row" style="margin-left: auto; align-items: center">
          <div style="width: 40px" class="rectangle"><p>{{page+1}}</p></div>
          <p style="margin-left: 16px; margin-right: 16px">of {{usersPage.totalPages}}</p>
          <div (click)="prevPage()" class="rectangle button"><img [style.filter]="usersPage.first ? '' : 'brightness(0.1)'" style="transform: rotate(180deg)" src="/assets/ico/arrow_right.png"></div>
          <div (click)="nextPage()" class="rectangle button"><img [style.filter]="usersPage.last ? '' : 'brightness(0.1)'" src="/assets/ico/arrow_right.png"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="shadow" *ngIf="showUserDeletePopup">
  <div class="delete-popup">
    <div class="header row">
      <h6>Delete user</h6>
      <img (click)="showUserDeletePopup = false" class="button" src="/assets/ico/close_grey.png">
    </div>
    <hr>
    <div class="delete-main-block">
      <p>Are you sure you want to delete this user?</p>
      <div class="row">
        <div (click)="deleteAdmin()" style="margin-left: auto; margin-right: 16px; background: #64DE9D;" class="button">
          <p>Delete</p>
        </div>
        <div (click)="showUserDeletePopup = false" style="background: #CED4DA;" class="button">
          <p>Cancel</p>
        </div>
      </div>
    </div>
  </div>
</div>
