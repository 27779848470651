import {Inject, Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Pagination} from "../shared/model/pagination";
import {User} from "../shared/model/user";
import {Event} from "../shared/model/event";

@Injectable()
export class EventService {
  constructor(private http: HttpClient,
              @Inject('API_REST_URL_ADMIN_EVENT_PAGES') private eventsUrl: string) {
  }

  public getPaginationByTitle(page: number, title: string){
    const params = new HttpParams().append('page', page).append('title', title);
    return this.http.get<Pagination<Event>>(environment.apiUrl + this.eventsUrl + '/paginated', {params, withCredentials: true});
  }

  public create(event: Event): Observable<any>{
    return this.http.post(environment.apiUrl + this.eventsUrl, event, {headers: new HttpHeaders().set('Content-Type', 'application/json'), withCredentials: true, observe: 'response'});
  }

  public update(event: Event): Observable<any>{
    return this.http.put(environment.apiUrl + this.eventsUrl, event, {headers: new HttpHeaders().set('Content-Type', 'application/json'), withCredentials: true, observe: 'response'});
  }

  public delete(id: number): Observable<any>{
    const params = new HttpParams().append('id', id);
    return this.http.delete(environment.apiUrl + this.eventsUrl, {params: params, headers: new HttpHeaders().set('Content-Type', 'application/json'), withCredentials: true, observe: 'response'});
  }
}
