export class User {
  id: number;
  email: string;
  password: string;
  newPassword: string;
  role: string;
  constructor(obj?: any) {
    this.id = obj && obj.id || null;
    this.email = obj && obj.email || null;
    this.password = obj && obj.password || null;
    this.newPassword = obj && obj.newPassword || null;
    this.role = obj && obj.role || null;
  }
}
