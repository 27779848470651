import {Component, OnInit, Output} from '@angular/core';
import {AuthenticationService} from "../../../core/service/authentication.service";
import { EventEmitter } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {UserService} from "../../../core/service/user.service";
import {Router} from "@angular/router";
import {User} from "../../../core/shared/model/user";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm: FormGroup;
  loginSubmitted = false;
  showError = false;

  constructor(private route: Router, private loginService: AuthenticationService, private userService: UserService, public fb: FormBuilder) {
    this.loginForm = this.fb.group({
      login: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  login() {
    this.loginSubmitted = true;
    if (this.loginForm.invalid) return;
    this.loginService.login(this.loginForm.controls['login'].value, this.loginForm.controls['password'].value).subscribe((user) => {
      if (user.role === 'ROLE_ADMIN'){
        this.route.navigateByUrl('/');
      }
    },
      (error) => {
        this.showError = true;
        setTimeout(() => { this.showError = false; }, 5000);
      });
  }

}
