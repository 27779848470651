import {Inject, Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Pagination} from "../shared/model/pagination";
import {User} from "../shared/model/user";

@Injectable()
export class UserService {
  constructor(private http: HttpClient,
              @Inject('API_REST_URL_ADMIN') private usersUrl: string) {
  }

  public getUser(): Observable<User>{
    return this.http.get<User>(environment.apiUrl + "/user",{withCredentials: true});
  }

  public getPaginationByName(page: number, name: string){
    const params = new HttpParams().append('page', page).append('name', name);
    return this.http.get<Pagination<User>>(environment.apiUrl + this.usersUrl, {params, withCredentials: true});
  }

  public createAdmin(user: User): Observable<any>{
    return this.http.post(environment.apiUrl + this.usersUrl, user, {headers: new HttpHeaders().set('Content-Type', 'application/json'), withCredentials: true, observe: 'response'});
  }

  public updateAdmin(user: User): Observable<any>{
    return this.http.put(environment.apiUrl + this.usersUrl, user, {headers: new HttpHeaders().set('Content-Type', 'application/json'), withCredentials: true, observe: 'response'});
  }

  public deleteAdmin(id: number): Observable<any>{
    const params = new HttpParams().append('id', id);
    return this.http.delete(environment.apiUrl + this.usersUrl, {params: params, headers: new HttpHeaders().set('Content-Type', 'application/json'), withCredentials: true, observe: 'response'});
  }
}
