import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from "../../../environments/environment";
import {User} from "../../core/shared/model/user";
import {AuthenticationService} from "../../core/service/authentication.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  dropdownOpened = false;
  @Output() newUserClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() user: User = new User();
  environment = environment;
  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
  }

  logout(): void{
      this.authService.logout().subscribe((response) =>document.location.reload(),
        (error) =>  document.location.reload())
  }
}
