<div class="header row">
  <div class="inner row pos-relative">
    <div (click)="newUserClicked.emit()" class="new-button button"><p>New User</p></div>
    <div (click)="dropdownOpened = !dropdownOpened" style="margin-left: auto;cursor: pointer;" class="user-logo button"><p>{{user.email.charAt(0)}}</p></div>
    <div *ngIf="dropdownOpened" class="dropdown flex-column">
      <img style="width: 12px; height: 8px;margin-right: 18px;margin-left: auto;" src="/assets/ico/header-dropdown-rectangle.png">
      <div class="dropdown-outer">
        <div class="dropdown-inner">
          <div class="row">
            <div class="user-logo"><p>{{user.email.charAt(0)}}</p></div>
            <p style="margin-left: 16px; color: #A6B0CF;" class="dropdown-text">{{user.email}}</p>
          </div>
          <hr style="opacity: 0.3;">
          <div style="margin-top: 24px" class="row button">
            <img style="margin-left: 10px;height: 25px" src="/assets/ico/logout.png">
            <p (click)="logout()" style="margin-left: 28px; color: #A6B0CF;margin-top: 0" class="dropdown-text button">Logout</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
