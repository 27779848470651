import {Component, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {User} from "../../core/shared/model/user";
import {UserService} from "../../core/service/user.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent{

  showLogin = true;
  activeBlock = 'landingPages';
  user: User = new User();

  userForEdit: User = new User();
  environment = environment;

  constructor(private route: Router, private userService: UserService,private titleService:Title) {
    userService.getUser().subscribe((user) => {
      if (user.role === 'ROLE_ADMIN') {
        this.showLogin = false;
        this.user = user;
      } else {
        this.route.navigateByUrl('/login');
      }
    })
  }

  resetTitle(): void{
    this.titleService.setTitle("AstraZeneca CMS");
  }
}
